.specialties-card {
  .specialties-paper {
    padding: 28px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: #d1d1e0;
  }

  .specialties-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #bebebe;
  }

  .specialties-text {
    margin: 2px auto;
    text-align: center;
    font-size: 16px;
  }
}

.specialties-card :hover {
  cursor: pointer;
}

.bg-danger {
  background-color: red !important;
}

.bg-warning {
  background-color: yellow !important;
}

.bg-success {
  background-color: green !important;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 3rem 0;
}

.my-3 {
  margin: 2rem;
}

.carousel-section {
  display: flex;
  width: 100%;

  .rated-section {
    display: flex;
    width: 35%;
    justify-content: flex-end;
    align-items: center;

    .left-container {
      margin: 1rem;
    }
  }

  .carousel-container {
    width: 65%;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;

    .carousel-container {
      justify-content: center;
      width: 100%;
    }

    .rated-section {
      justify-content: center;
      width: 100%;
    }
  }
}