.normal-text {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4rem;
  line-break: anywhere;

  // background-color: #ede7f6;
}

.text {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4rem;
}

.link {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4rem;
  text-decoration: none;
  color: #1565c0;
}

.link:active {
  color: #1565c0;
}

.MuiLink-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}
