@mixin hero-search {
  .hero-search {
    display: flex;
    justify-content: center;

    .search-stack {
      border: 1px solid black;
      border-radius: 4px;
      width: 70%;

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      .search-inputs {
        padding: 0px 11px;
        flex: 1 1 0;

        .group-search {
          @media screen and (max-width: 960px) {
            padding-inline-start: 0.8rem;
            height: 100%;

            .MuiFormControl-root {
              height: 100%;

              .MuiInputBase-root {
                height: 100%;
              }
            }
          }
        }

        .location-search {
          @media screen and (max-width: 960px) {
            padding-inline-start: 0.8rem;
            height: 100%;

            .MuiFormControl-root {
              height: 100%;

              .MuiInputBase-root {
                height: 100%;
              }
            }
          }
        }

        @media screen and (max-width: 960px) {
          flex-basis: 75px;
          width: 100%;
          padding: 0px;
        }
      }

      .btn-stack {
        @media screen and (max-width: 960px) {
          width: 100%;
        }

        .search-btn {
          padding: 12px 20px;
          border-radius: 0px 4px 4px 0px;
          height: 75px;

          @media screen and (max-width: 960px) {
            border-radius: 0px 0px 4px 4px;
            height: 65px;
          }

          &.MuiButtonBase-root {
            svg {
              @media screen and (max-width: 960px) {
                visibility: hidden;
              }
            }

            @media screen and (max-width: 960px) {
              &::after {
                content: "Find";
                position: absolute;
                color: whitesmoke;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}