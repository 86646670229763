.mainCountriesBody {
    max-width: 1400px;
    margin: auto;

    .primaryHeadingTypography {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 2em;
        text-transform: capitalize;
    }

    .countriesFlags {
        width: 50px;
        height: 50px;
    }

    .secondaryHeadingTypography {
        margin-bottom: 10px;
        font-size: 1.3em;
        text-transform: capitalize;
        font-weight: normal;
    }
}