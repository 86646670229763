@import "../mixin";

.hero-header {
  display: block;
  // max-width: 1600px;
  // padding-inline: 100px;
  margin-inline: auto;
  @include hero-search;

  .hero-search {
    width: 75%;

    .search-stack {
      width: 100%;

      .btn-stack {
        .search-btn {
          height: 55px;
        }
      }
    }

    @media screen and (max-width: "1040px") {
      display: none;
    }
  }
}