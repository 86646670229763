.contactBody {
    max-width: 1400px;
    margin: auto;

    .contactUsForm {
        display: flex;
        flex-direction: column;
        gap: 20px;

        max-width: 800px;
        margin: auto;
        padding-top: 0px !important;

    }

    .contactUsCard {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border: 1px solid gray;
        border-radius: 20px;
        width: 200px;
        height: 150px;
        padding: 1em;
    }

    .socialImage {
        width: 35px;
        height: 35px;
    }

    .primaryHeadingTypography {
        margin-bottom: 10px;
        margin-top: 10px;
        // font-size: 1.8em;
        text-transform: capitalize;
        // font-size: 25px;
        line-height: 45px;
        font-weight: 600;
        text-align: center;

    }

    .secondaryHeadingTypography {
        margin: 10px;
        font-size: 1.3em;
        text-transform: capitalize;
        font-weight: 500;
    }
}