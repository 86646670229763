@import "../themes-vars.module";

body {
  display: block;
  margin: 0;
  padding: 0;
  background-color: $paper;

  .app-layout {
    display: block;

    .hero-header {
      .items-collection {
        padding-block: 0.7rem;
      }
    }

    width: 90%;
    margin: auto;
  }
}