.hero-section {
  // temporary
  max-width: 1600px;
  width: 100%;
  margin-inline: auto;
  padding-block: 70px 120px;
  @include hero-search;
}

.custom-border {
  border-radius: 14px;
}