.home-page {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;


    // Navbar Section
    .navbar {
        // background-color: #cdd5df;

        .navbar-section {
            display: flex;
            justify-content: space-between;
            padding: 1em 2em;

            max-width: 1400px;
            margin: auto;
        }

        button {
            font-size: 16px;
        }
    }

}